<template>
  <transition name="modalwindow">
    <div class="modal-mask fixed top-0 left-0 w-full h-full outline-none fade">
      <div
        class="
          modal-container
          w-11/12
          mt-5
          mb-10
          mx-auto
          rounded
          shadow-lg
          z-50
          h-full
          bg-white
        "
      >
        <div class="pt-2 fixed h-12">
          <slot name="header">
            <i
              class="
                fas
                fa-times-circle
                cursor-pointer
                fixed
                right-0
                items-center
                mt-3
                mr-20
                text-pink-red text-5xl
                z-50
              "
              @click="$emit('close')"
            ></i>
            <div class="backbar text-orange-400 inline-block mx-3 mt-3">
              {{ lessonName }}
            </div>

            <div class="backbar" v-html="className"></div>
          </slot>
        </div>
        <slot />

        <div class="backbar opacity-75" v-if="hasNotes">
          {{ notes }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "evidenceimage",
  props: {
    className: { type: String, required: false },
    lessonName: { type: String, required: false },
    notes: { type: String, required: false },
  },
  computed: {
    hasNotes() {
      return this.notes != undefined && this.notes.length > 0;
    },
  },
};
</script>

<style scoped>
.backbar {
  @apply font-semibold text-sm mb-2 inline-block  p-3  py-4 bg-white  rounded;
}
</style>
